<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <h2 class="mt-4">
      {{ $t("usersBought") }}
      <span class="text-muted">{{ getBoughtItems.length }}</span>
    </h2>
    <h5 class="text-muted mt-2 mb-4">
      {{ $t("usersBoughtDescription") }}
    </h5>
    <div class="about_tab_content">
      <Tables
        :searchPlaceholder="$t('searchProductName')"
        @change:search="SET_SEARCH"
        :loading="getBoughtItemsLoading"
        :headers="headers"
        :items="getBoughtItems"
      >
        <template #title="{ item }">
          <router-link
            :to="`/product/${FIND_TYPE_ALT(item.type).id}-${item.id}`"
            >{{ item.title }}</router-link
          >
        </template>
        <template #createdAt="{ item }">
          <span :title="formatter(new Date(item.createdAt))">
            {{ formatter(new Date(item.createdAt)) }}</span
          >
        </template>
        <template #price="{ item }">
          <h6>
            {{ Currency({ price: item.price }, item.currency || "TRY") }}
          </h6>
        </template>
        <template #status="{ item }">
          <span
            v-if="item.type !== 3"
            class="badge badge-success"
            :class="{
              'badge-success': item.status === 2,
              'badge-danger': item.status === 0,
              'badge-warning': item.status === 1,
            }"
          >
            {{
              item.status === 1
                ? $t("waiting")
                : item.status === 2
                ? $t("done")
                : item.status === 0
                ? $t("canceled")
                : ""
            }}
          </span>
        </template>
        <template #discount="{ item }">
          <h6 class="text-info">{{ item.discount }}%</h6>
        </template>
        <template #discountedPrice="{ item }">
          <h6
            :class="{
              'text-success': item.status === 2,
              'text-danger': item.status === 0,
              'text-warning': item.status === 1,
            }"
          >
            {{ item.status === 2 ? "+" : "" }}
            {{
              Currency(
                { price: item.price, discount: item.discount },
                item.currency || "TRY"
              )
            }}
          </h6>
        </template>
        <template #options="{ item }">
          <button
            v-if="item.type !== 3"
            class="btn btn-pill"
            @click="
              activeItem = item;
              showActiveUserUpdateModal = true;
            "
          >
            {{ $t("view") }}
          </button>
          <button
            @click="
              $store.commit('SET_MAIL_DRAWER', {
                status: true,
                form: {
                  to: user.email,
                  bcc: '',
                  subject: $t('aboutBoughtProduct', {
                    typeTitle: item.type.title,
                    productTitle: item.title,
                  }),
                  content: '',
                  files: [],
                },
              })
            "
            class="btn btn-pill donate"
          >
            {{ $t("sendMail") }}
          </button>
        </template>
      </Tables>
    </div>
    <UserListOfEventUpdate
      :activeUserUpdate="{
        ...user,
        status: activeItem.status,
        orderNo: activeItem.orderNo,
        promotion: null,
      }"
      :product="{ ...activeItem, type: FIND_TYPE_ALT(activeItem.type) }"
      v-model="showActiveUserUpdateModal"
      @refresh="GET_LIST(this.search, true)"
    ></UserListOfEventUpdate>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { Currency } from "gurhan/helpers/Currency";
import UserListOfEventUpdate from "../Product/UserListOfEventUpdate.vue";
import Types from "../Type/Types";
export default {
  components: { Tables, UserListOfEventUpdate },
  mixins: [Types],
  props: {
    user: {
      default: () => {},
    },
  },
  data() {
    return {
      showActiveUserUpdateModal: false,
      activeItem: {},
      search: "",
      page: 1,
      limit: 10,
      count: 10,
      headers: [
        {
          title: this.$t("productName"),
          value: "title",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("productPrice"),
          value: "price",
          col: 6,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("purchasedAt"),
          value: "createdAt",
          col: 6,
          md: 6,
          lg: 3,
          xl: 2,
        },
        {
          title: this.$t("discount"),
          value: "discount",
          col: 6,
          md: 6,
          lg: 1,
          xl: 1,
        },
        {
          title: this.$t("payMoney"),
          value: "discountedPrice",
          col: 6,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: "",
          value: "status",
          col: 6,
          md: 6,
          lg: 2,
          xl: 2,
        },
      ],
    };
  },
  computed: {
    getBoughtItems() {
      const userBoughtItems =
        this.$store.state.CustomerList.customerBoughtItemList.find(
          (c) => c.userId === this.user.id
        );
      if (userBoughtItems)
        return userBoughtItems.data.filter((d) =>
          d.title.toLowerCase().includes(this.search.toLowerCase())
        );
      return [];
    },
    getBoughtItemsLoading() {
      return this.$store.state.CustomerList.customerBoughtItemAreLoading;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST(search, true);
    },
    Currency,
    formatter(ms) {
      return new Date(ms).toLocaleDateString(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
    GET_LIST(search = "", force = false) {
      this.$store.dispatch("GET_CUSTOMER_DETAIL_BOUGHT_ITEMS", {
        id: this.user.id,
        force,
        search,
      });
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST("");
    if (this.getSettings.departmentId === 2) {
      this.headers.splice(3, 2);
      this.headers.splice(1, 1);
    }
  },
};
</script>

<style></style>
